import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { Styles } from '../../styles/styles';

const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: '#e9e7e7',
  zIndex: Styles.zIndexSiteFooter,
  padding: theme.spacing(4),
  marginTop: 'auto',
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  marginRight: theme.spacing(2),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const SiteFooter = () => {
  return (
    <FooterContainer>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <FooterLink to='/'>Privacy Policy</FooterLink>
        <FooterLink to='/'>More Info</FooterLink>
        <FooterLink to='/'>About Us</FooterLink>
        <FooterLink to='/'>How It Works</FooterLink>
        <FooterLink to='/'>Contact Us</FooterLink>
      </Box>
      <Box display='flex' justifyContent='center' alignItems='center' mt={2}>
        <Typography variant='body2' color='textSecondary'>
          © {new Date().getFullYear()} Skill Scale. All rights reserved.
        </Typography>
      </Box>
    </FooterContainer>
  );
};
