import { QUIZ_TYPES } from '../../../constants/quizTypes';

export const QUIZ_TAKE_PAGE_REDUCER_TYPES = {
  LOAD_QUIZ: 'LOAD_QUIZ',
  LOAD_QUIZ_SUCCESS: 'LOAD_QUIZ_SUCCESS',
  LOAD_DATA_FAILURE: 'LOAD_DATA_FAILURE',
  SET_ANSWERED_QUESTIONS: 'SET_ANSWERED_QUESTIONS',
  SET_OPENED_QUESTION: 'SET_OPENED_QUESTION',
  SET_OPENED_QUESTION_INDEX: 'SET_OPENED_QUESTION_INDEX',
};

export const quizTakePageReducer = (state, action) => {
  switch (action.type) {
    case QUIZ_TAKE_PAGE_REDUCER_TYPES.LOAD_QUIZ:
      return { ...state, waiting: true };
    case QUIZ_TAKE_PAGE_REDUCER_TYPES.LOAD_QUIZ_SUCCESS:
      return {
        ...state,
        waiting: false,
        errorMsg: null,
        questions: action.questions,
        quizTitle: action.quizTitle,
        maxQuizTakerNum: action.maxQuizTakerNum,
        quizType: action.quizType,
        generatedByAi: action.generatedByAi,
      };
    case QUIZ_TAKE_PAGE_REDUCER_TYPES.LOAD_DATA_FAILURE:
      return { ...state, waiting: false, errorMsg: action.payload };
    case QUIZ_TAKE_PAGE_REDUCER_TYPES.SET_ANSWERED_QUESTIONS:
      return {
        ...state,
        answeredQuestions: { ...state.answeredQuestions, ...action.payload.answeredQuestions },
        openedQuestion: action.payload.openedQuestion,
      };
    case QUIZ_TAKE_PAGE_REDUCER_TYPES.SET_OPENED_QUESTION:
      return { ...state, openedQuestion: action.payload };
    case QUIZ_TAKE_PAGE_REDUCER_TYPES.SET_OPENED_QUESTION_INDEX:
      return { ...state, openedQuestion: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const initialQuizTakePageState = {
  questions: [],
  selectedQuestion: null,
  waiting: false,
  errorMsg: null,
  quizTitle: null,
  quizType: QUIZ_TYPES.ONLINE,
  answeredQuestions: {},
  openedQuestion: null,
};
