import { IconButton, Tooltip } from '@mui/material';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

export const CloseButton = ({ closeModal }) => {
  return (
    <Tooltip title='Discard changes' placement='top'>
      <StyledButton onClick={closeModal}>
        <MdClose color='black' />
      </StyledButton>
    </Tooltip>
  );
};

const StyledButton = styled(IconButton)`
  position: absolute !important;
  right: 16px;
`;
