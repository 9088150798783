export const SignupActionTypes = {
  CHANGE_NAME: 'CHANGE_NAME',
  CHANGE_LAST_NAME: 'CHANGE_LAST_NAME',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_REPEAT: 'CHANGE_PASSWORD_REPEAT',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  START_SIGNUP: 'START_SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  CLOSE_ERROR: 'CLOSE_ERROR',
};

export const SignupReducer = (state, { type, payload }) => {
  switch (type) {
    case SignupActionTypes.CHANGE_NAME:
      return { ...state, name: payload, errorMsg: null };
    case SignupActionTypes.CHANGE_LAST_NAME:
      return { ...state, lastName: payload, errorMsg: null };
    case SignupActionTypes.CHANGE_EMAIL:
      return { ...state, email: payload, errorMsg: null };
    case SignupActionTypes.CHANGE_PASSWORD:
      return { ...state, password: payload, errorMsg: null };
    case SignupActionTypes.CHANGE_PASSWORD_REPEAT:
      return { ...state, passwordRepeat: payload, errorMsg: null };
    case SignupActionTypes.VALIDATION_ERROR:
      return { ...state, errorMsg: payload };
    case SignupActionTypes.START_SIGNUP:
      return { ...state, waiting: true, errorMsg: null };
    case SignupActionTypes.SIGNUP_SUCCESS:
      return { ...state, waiting: false, finished: true, errorMsg: null };
    case SignupActionTypes.SIGNUP_FAILURE:
      return { ...state, waiting: false, errorMsg: payload };
    case SignupActionTypes.CLOSE_ERROR:
      return { ...state, errorMsg: null };
    default:
      throw new Error('Unhandled action type: ' + type);
  }
};

export const initialSignupState = {
  name: '',
  lastName: '',
  email: '',
  password: '',
  passwordRepeat: '',
  errorMsg: null,
  waiting: false,
  finished: false,
};
