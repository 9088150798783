import { Box, Button, TextField } from '@mui/material';
import { useCallback, useEffect, useReducer } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { QUIZ_CREATOR_REDUCER_TYPES, quizCreatorReducer } from './quiz_page_reducer';
import { QuizQuestion } from './QuizQuestion';
import { EachPageWrapper } from '../../EachPageWrapper';
import { FixedSidebar } from '../../fixedSidebar/FixedSidebar';
import { CreateQuestionModal } from '../../modals/createQuestion/CreateQuestionModal';
import ErrorComponent from '../../ui/error_component';
import { Waiting } from '../../waiting';
import { ApiClient } from '../../../api/apiClient';
import { handleApiError } from '../../../api/axiosMethods';
import { QUIZ_CREATOR_SIDEBAR_ITEMS } from '../../../constants';
import { QUIZ_TYPES } from '../../../constants/quizTypes';

export const QuizPage = () => {
  const { quizId } = useParams();
  const auth = useAuthUser();
  const userId = auth.user.id;

  const initialState = {
    questions: [],
    selectedQuestion: null,
    isModalOpen: false,
    waiting: false,
    errorMsg: null,
    quizTitle: null,
    //TODO: Finish the logic of generation by AI
    generatedByAi: false,
    quizType: QUIZ_TYPES.ONLINE,
    maxQuizTakerNum: 100,
  };

  const [state, dispatch] = useReducer(quizCreatorReducer, initialState);
  const {
    questions,
    selectedQuestion,
    isModalOpen,
    errorMsg,
    waiting,
    quizTitle,
    generatedByAi,
    quizType,
    maxQuizTakerNum,
  } = state;

  // If there is any quiz_id, it means that we have been redirected to this page and we should load the quiz from the BE
  useEffect(() => {
    if (!quizId) return;
    dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.START_LOAD_OR_SAVE_QUIZ });
    ApiClient.getQuiz(userId, quizId)
      .then((response) => {
        if (response.data.success) {
          const r = response.data.payload.quiz;
          dispatch({
            type: QUIZ_CREATOR_REDUCER_TYPES.LOAD_QUIZ_SUCCESS,
            questions: r.questions,
            quizTitle: r.title,
            maxQuizTakerNum: r.max_quiz_takers_num,
            quizType: r.type,
            generatedByAi: r.generated_by_ai,
          });
        } else {
          dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.LOAD_DATA_FAILURE, payload: response.data.message });
        }
      })
      .catch((error) =>
        handleApiError(error, (msg) => dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.LOAD_DATA_FAILURE, payload: msg }))
      );
  }, [quizId]);

  const handleQuizTitleChange = useCallback((event) => {
    dispatch({
      type: QUIZ_CREATOR_REDUCER_TYPES.SET_QUIZ_TITLE,
      payload: event.target.value,
    });
  }, []);

  const handlemaxQuizTakerNumChange = useCallback((event) => {
    dispatch({
      type: QUIZ_CREATOR_REDUCER_TYPES.SET_MAX_QUIZ_TAKER_NUMBER,
      payload: parseInt(event.target.value, 10) || 0,
    });
  }, []);

  const setQuestions = (questions) => dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.SET_QUESTIONS, payload: questions });
  const setSelectedQuestion = (question) =>
    dispatch({
      type: QUIZ_CREATOR_REDUCER_TYPES.SET_SELECTED_QUESTION,
      payload: question,
    });
  const setIsModalOpen = (isModalOpen) =>
    dispatch({
      type: QUIZ_CREATOR_REDUCER_TYPES.SET_IS_MODAL_OPEN,
      payload: isModalOpen,
    });

  const saveQuestion = (question, isCreated) => {
    if (isCreated) {
      setQuestions([...questions, { ...question, id: questions.length }]);
    } else {
      setQuestions(questions.map((q) => (q.id === selectedQuestion.id ? question : q)));
    }
    setSelectedQuestion(null);
  };

  const removeQuestion = (removeId) => {
    const newQuestions = questions.filter((_, id) => id !== removeId);
    newQuestions.forEach((question, id) => (question.id = id));
    setQuestions(newQuestions);
  };

  const saveQuiz = () => {
    //TODO: Add some validation in here
    dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.START_LOAD_OR_SAVE_QUIZ });
    // If we have a quizId, it means the quiz is already built in our DB and we would like edit it only
    const resultingPromise = quizId
      ? ApiClient.editQuiz(userId, quizId, questions, quizTitle, generatedByAi, quizType, maxQuizTakerNum)
      : ApiClient.createQuiz(userId, questions, quizTitle, generatedByAi, quizType, maxQuizTakerNum);
    resultingPromise
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.SAVE_QUIZ_SUCCESS });
        } else {
          dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.LOAD_DATA_FAILURE, payload: response.data.message });
        }
      })
      .catch((error) =>
        handleApiError(error, (msg) => dispatch({ type: QUIZ_CREATOR_REDUCER_TYPES.LOAD_DATA_FAILURE, payload: msg }))
      );
  };

  return (
    <EachPageWrapper>
      <FixedSidebar items={QUIZ_CREATOR_SIDEBAR_ITEMS} />
      {errorMsg ? (
        <ErrorComponent message={errorMsg} />
      ) : waiting ? (
        <Waiting big />
      ) : (
        <Wrapper>
          <TextField
            label='Quiz Title'
            variant='outlined'
            value={quizTitle}
            onChange={handleQuizTitleChange}
            style={{ background: 'white' }}
            margin='normal'
          />
          <TextField
            label='Max Quiz Taker Number'
            type='number'
            variant='outlined'
            value={maxQuizTakerNum}
            onChange={handlemaxQuizTakerNumChange}
            style={{ background: 'white' }}
            margin='normal'
          />
          <CardsContainer>
            {questions.map((question) => (
              <QuizQuestion
                key={question.id}
                question={question}
                setSelectedQuestion={setSelectedQuestion}
                setIsModalOpen={setIsModalOpen}
                removeQuestion={removeQuestion}
              />
            ))}
          </CardsContainer>

          <Box display='flex' justifyContent='space-between' width='100%' marginTop={4}>
            <AddQuestionButton variant='contained' onClick={() => setIsModalOpen(true)}>
              Add question
            </AddQuestionButton>

            {questions.length >= 1 && (
              <SaveButton variant='contained' color='success' onClick={() => saveQuiz()}>
                Save quiz
              </SaveButton>
            )}
          </Box>
        </Wrapper>
      )}

      {/*<QuestionPanel question={selectedQuestion}/>*/}
      {/*Will be added later*/}

      {isModalOpen && (
        <CreateQuestionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          saveQuestion={saveQuestion}
        />
      )}
    </EachPageWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 64px;
  margin-left: 100px;
`;

const AddQuestionButton = styled(Button)`
  width: 200px;
  margin-left: 64px !important;
`;

const SaveButton = styled(Button)`
  width: 200px;
  margin-right: 108px !important;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
