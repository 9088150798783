import { Button } from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { QuizTakeQuestion } from './QuizTakeQuestion';
import { QuizTakeReview } from './QuizTakeReview';
import { initialQuizTakePageState, QUIZ_TAKE_PAGE_REDUCER_TYPES, quizTakePageReducer } from './reducer';
import { Waiting } from '../../waiting';
import { ApiClient } from '../../../api/apiClient';
import { handleApiError } from '../../../api/axiosMethods';

export const QuizTakePage = () => {
  const { quizId } = useParams();

  const [state, dispatch] = useReducer(quizTakePageReducer, initialQuizTakePageState);
  const { waiting, questions, quizTitle, openedQuestion, answeredQuestions } = state;

  // TODO send BE request when starting a quiz and fetch isStarted from BE
  const [isQuizStarted, setIsQuizStarted] = useState(false);

  useEffect(() => {
    dispatch({ type: QUIZ_TAKE_PAGE_REDUCER_TYPES.LOAD_QUIZ });

    // TODO change to actual userId
    ApiClient.getQuiz(3, quizId)
      .then((response) => {
        if (response.data.success) {
          const quiz = response.data.payload.quiz;
          dispatch({
            type: QUIZ_TAKE_PAGE_REDUCER_TYPES.LOAD_QUIZ_SUCCESS,
            questions: quiz.questions,
            quizTitle: quiz.title,
            maxQuizTakerNum: quiz.max_quiz_takers_num,
            quizType: quiz.type,
            generatedByAi: quiz.generated_by_ai,
          });
        } else {
          dispatch({ type: QUIZ_TAKE_PAGE_REDUCER_TYPES.LOAD_DATA_FAILURE, payload: response.data.message });
        }
      })
      .catch((error) =>
        handleApiError(error, (msg) => dispatch({ type: QUIZ_TAKE_PAGE_REDUCER_TYPES.LOAD_DATA_FAILURE, payload: msg }))
      );
  }, []);

  const handleStartQuiz = () => {
    dispatch({ type: QUIZ_TAKE_PAGE_REDUCER_TYPES.SET_OPENED_QUESTION, payload: 0 });
    setIsQuizStarted(true);
  };

  if (waiting) return <Waiting big />;

  return !isQuizStarted ? (
    <StyledContainer>
      <h3>{quizTitle}</h3>
      <StyledButton color='success' variant='contained' onClick={handleStartQuiz}>
        Start quiz
      </StyledButton>
    </StyledContainer>
  ) : openedQuestion + 1 <= questions.length ? (
    <QuizTakeQuestion
      questions={questions}
      answeredQuestions={answeredQuestions}
      openedQuestion={openedQuestion}
      dispatch={dispatch}
    />
  ) : (
    <QuizTakeReview questions={questions} answeredQuestions={answeredQuestions} dispatch={dispatch} />
  );
};

const StyledContainer = styled.div`
  background: white;
  width: 600px;
  height: 400px;
  margin: auto;
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
`;

const StyledButton = styled(Button)`
  margin-top: 36px;
  padding: 12px 36px;
  border-radius: 16px;
`;
