import { Button } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import { AnswerField } from './answer/AnswerField';
import { AnswerOptions } from './answer/AnswerOptions';
import { QUIZ_TAKE_PAGE_REDUCER_TYPES } from './reducer';
import { QUESTION_TYPES } from '../../../constants/questionTypes';

export const QuizTakeQuestion = ({ questions, answeredQuestions, openedQuestion, dispatch }) => {
  const [answer, setAnswer] = useState(answeredQuestions[questions[openedQuestion]?.id] ?? null);

  const handlePrevQuestion = () => {
    dispatch({ type: QUIZ_TAKE_PAGE_REDUCER_TYPES.SET_OPENED_QUESTION, payload: openedQuestion - 1 });
    setAnswer(answeredQuestions[questions[openedQuestion - 1].id]);
  };

  const handleNextQuestion = () => {
    dispatch({
      type: QUIZ_TAKE_PAGE_REDUCER_TYPES.SET_ANSWERED_QUESTIONS,
      payload: { answeredQuestions: { [question.id]: answer }, openedQuestion: openedQuestion + 1 },
    });
    setAnswer(answeredQuestions[questions[openedQuestion + 1]?.id] ?? null);
  };

  const question = questions[openedQuestion];

  return (
    <StyledContainer>
      <StyledQuestionNumber>Q. {openedQuestion + 1}</StyledQuestionNumber>

      <StyledQuestion>
        <h4>Question</h4>
        <p>{question.questionText}?</p>
      </StyledQuestion>

      <div>
        <h4>Answer</h4>
        {[QUESTION_TYPES.MULTIPLE_CHOICE, QUESTION_TYPES.TRUE_OR_FALSE].includes(question.questionType) && (
          <AnswerOptions question={question} answer={answer} setAnswer={setAnswer} />
        )}
        {[QUESTION_TYPES.SHORT_ANSWER, QUESTION_TYPES.OPEN_ENDED].includes(question.questionType) && (
          <AnswerField answer={answer} setAnswer={setAnswer} />
        )}
      </div>

      {openedQuestion !== 0 && (
        <StyledPrevButton variant='contained' onClick={handlePrevQuestion}>
          Previous
        </StyledPrevButton>
      )}

      {answer != null && (
        <StyledNextButton variant='contained' onClick={handleNextQuestion}>
          Next
        </StyledNextButton>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 600px;
  margin: auto;
  text-align: left;
`;

const StyledQuestionNumber = styled.h2`
  border-bottom: 1px solid #92b1f1;
  padding-bottom: 16px;
`;

const StyledQuestion = styled.div`
  border-bottom: 1px solid #92b1f1;
  padding-bottom: 8px;
`;

const StyledPrevButton = styled(Button)`
  float: left;
  margin-top: 24px !important;
`;

const StyledNextButton = styled(Button)`
  float: right;
  margin-top: 24px !important;
`;
