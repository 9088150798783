import { Box, Button, Container, styled, TextField } from '@mui/material';
import { useContext } from 'react';
import { useCallback, useReducer } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useNavigate } from 'react-router-dom';

import { initialSignupState, SignupActionTypes, SignupReducer } from './reducer';
import { EachPageWrapper } from '../EachPageWrapper';
import { OverlayingError } from '../ui/overlaying_error';
import { Waiting } from '../waiting';
import { ApiClient } from '../../api/apiClient';
import { handleApiError } from '../../api/axiosMethods';
import { ModeContext } from '../../contexts/ModeContextProvider';
import { getModeLabel, MODES } from '../../modes';

export const Signup = () => {
  const signIn = useSignIn();
  const navigate = useNavigate();

  const { mode } = useContext(ModeContext);

  const [state, dispatch] = useReducer(SignupReducer, initialSignupState);

  const { name, lastName, email, password, passwordRepeat, waiting, finished, errorMsg } = state;

  const handleNameChange = (name) => dispatch({ type: SignupActionTypes.CHANGE_NAME, payload: name });

  const handleLastNameChange = (lastName) => dispatch({ type: SignupActionTypes.CHANGE_LAST_NAME, payload: lastName });

  const handleEmailChange = (email) => dispatch({ type: SignupActionTypes.CHANGE_EMAIL, payload: email });

  const handlePasswordChange = (password) => dispatch({ type: SignupActionTypes.CHANGE_PASSWORD, payload: password });

  const handlePasswordRepeatChange = (passwordRepeat) =>
    dispatch({ type: SignupActionTypes.CHANGE_PASSWORD_REPEAT, payload: passwordRepeat });

  const handleSignup = useCallback(() => {
    if (waiting || finished) return;
    if (!email) {
      dispatch({ type: SignupActionTypes.VALIDATION_ERROR, payload: 'Please enter your email.' });
      return;
    }
    if (!password) {
      dispatch({ type: SignupActionTypes.VALIDATION_ERROR, payload: 'Please enter your password.' });
      return;
    }
    dispatch({ type: SignupActionTypes.START_SIGNUP });
    //There must be a recaptcha
    ApiClient.login(email, password, mode)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: SignupActionTypes.SIGNUP_SUCCESS });
          if (mode === MODES.QUIZ_CREATOR) {
            navigate('/quiz-creators');
          } else {
            navigate('/quiz-takers');
          }
          // Here we receive the auth_token from BE
        } else {
          dispatch({ type: SignupActionTypes.SIGNUP_FAILURE, message: response.data.message });
        }
      })
      .catch((error) =>
        handleApiError(error, (msg) => dispatch({ type: SignupActionTypes.SIGNUP_FAILURE, message: msg }))
      );
  }, [waiting, finished, email, password]);

  const fieldStyle = { width: '350px', marginTop: '12px' };

  return (
    <EachPageWrapper title={getModeLabel(mode) + ' Signup'}>
      {waiting ? (
        <Waiting />
      ) : (
        <Box paddingY={4} borderRadius={1} sx={{ backgroundColor: '#eee' }}>
          <OverlayingError isOpen={errorMsg !== null} errorMessage={errorMsg} />
          <Container maxWidth='sm'>
            <FieldsWrapper>
              <TextField
                label='Name'
                required
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
                style={{ ...fieldStyle, marginTop: 0 }}
              />
              <TextField
                label='Last name'
                required
                value={lastName}
                onChange={(e) => handleLastNameChange(e.target.value)}
                style={fieldStyle}
              />
              <TextField
                label='Email'
                type='email'
                required
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
                style={fieldStyle}
              />
              <TextField
                label='Password'
                type='password'
                required
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
                style={fieldStyle}
              />
              <TextField
                label='Repeat password'
                type='password'
                required
                value={passwordRepeat}
                onChange={(e) => handlePasswordRepeatChange(e.target.value)}
                style={fieldStyle}
              />
            </FieldsWrapper>
            <Button variant='contained' color='primary' style={{ marginTop: '20px' }} onClick={handleSignup}>
              Sign up
            </Button>
          </Container>
        </Box>
      )}
    </EachPageWrapper>
  );
};

const FieldsWrapper = styled('div')({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
