import { styled } from '@mui/material';
import { useState } from 'react';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { FixedNavbar } from './components/fixedNavbar/FixedNavbar';
import { HomePage } from './components/homePage/HomePage';
import { Login } from './components/login/Login';
import { QuizCreator } from './components/quizCreator/QuizCreator';
import { QuizPage } from './components/quizCreator/quizzes/QuizPage';
import { QuizTakePage } from './components/quizTaker/quizTake/QuizTakePage';
import { QuizTaker } from './components/quizTaker/QuizTaker';
import { QuizzesPage } from './components/quizzesPage/QuizzesPage';
import { Signup } from './components/signup/Signup';
import { SiteFooter } from './components/siteFooter/SiteFooter';
import { ModeContextProvider } from './contexts/ModeContextProvider';
import { ThemeContext } from './contexts/themeContext';
import { MODES } from './modes';
import { getTheme } from './styles/getTheme';
import GlobalStyle from './styles/globalStyle';

const store = createStore({
  authName: 'SS_AUTH_TOKEN',
  authType: 'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});

const App = () => {
  const [mode, setMode] = useState(MODES.QUIZ_CREATOR);

  return (
    <AuthProvider store={store}>
      <BrowserRouter>
        <GlobalStyle background={getTheme(mode).palette.colors.c1} />

        <ModeContextProvider mode={mode} setMode={setMode}>
          <ThemeContext.Provider value={{ theme: getTheme(mode) }}>
            <AuthWrapper>
              <FixedNavbar />
              <Routes>
                <Route path='/' element={<HomePage mode={mode} setMode={setMode} />} />
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/quiz-creators' element={<QuizCreator />} />
                <Route path='/quiz-creators/quizzes' element={<QuizzesPage />} />
                <Route path='/quiz-creators/quizzes/create' element={<QuizPage />} />
                <Route path='/quiz-creators/quizzes/:quizId' element={<QuizPage />} />
                <Route path='/quiz/:quizId' element={<QuizTakePage />} />
                <Route path='/quiz-takers' element={<QuizTaker />} />
              </Routes>
              <SiteFooter />
            </AuthWrapper>
          </ThemeContext.Provider>
        </ModeContextProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

const AuthWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated()) {
    if (!['/', '/login', '/signup'].includes(pathname)) {
      console.log('NOT AUTHENTICATED!'); // TODO redirect to homepage after auth BE part is done
    }
  }

  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled('div')({
  textAlign: 'center',
  display: 'flex',
  height: '100dvh',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export default App;
