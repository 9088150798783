export const initialState = {
  waiting: false,
  errorMsg: null,
  quizzes: []
};

export const ActionTypes = {
  START_DELETING_QUIZ: 'START_DELETING_QUIZ_',
  DELETE_QUIZ_SUCCESS: 'DELETE_QUIZ_SUCCESS_',
  DELETE_QUIZ_FAILURE: 'DELETE_QUIZ_FAILURE_',
  START_LOAD_QUIZ: 'START_LOAD_QUIZ',
  LOAD_QUIZ_SUCCESS: 'LOAD_QUIZ_SUCCESS',
  LOAD_QUIZ_FAILURE: 'LOAD_QUIZ_FAILURE'
};


export function quizzesPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.START_DELETING_QUIZ:
    case ActionTypes.START_LOAD_QUIZ:
      return {
        ...state,
        waiting: true,
        errorMsg: null
      };
    case ActionTypes.DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        waiting: false,
        quizzes: state.quizzes.filter(quiz => quiz.id !== action.payload),
        errorMsg: null
      };
    case ActionTypes.LOAD_QUIZ_SUCCESS:
      return {
        ...state,
        quizzes: action.payload,
        waiting: false,
        errorMsg: null
      };
    case ActionTypes.DELETE_QUIZ_FAILURE:
    case ActionTypes.LOAD_QUIZ_FAILURE:
      return {
        ...state,
        waiting: false,
        errorMsg: action.payload
      };
    default:
      throw new Error('Invalid type ' + action.type);
  }
}
