import { QUESTION_TYPES } from '../../../constants/questionTypes';

export function validateQuestion(questionText, questionType, options, answer) {
  switch (questionType) {
    case QUESTION_TYPES.MULTIPLE_CHOICE:
    case QUESTION_TYPES.TRUE_OR_FALSE:
      if (options.length < 2 || !options.every((option) => option.text.trim())) return false;
      break;

    case QUESTION_TYPES.SHORT_ANSWER:
      if (!answer.trim()) return false;
      break;
  }

  return !!questionText.trim();
}
