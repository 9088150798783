import { createGlobalStyle } from 'styled-components';

import { getTheme } from './getTheme';
import museoSlab100 from '../assets/fonts/Museo_Slab_100.otf';
import { MODES } from '../modes';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Museo Slap';
    src: url(${museoSlab100} format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    margin: 0;
    padding: 0;
    background: ${(props) => props.background};
    font-family: ${getTheme(MODES.DEFAULT).typography.fontFamily};
  }
`;

export default GlobalStyle;
