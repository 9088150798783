import { EachPageWrapper } from '../EachPageWrapper';
import { FixedSidebar } from '../fixedSidebar/FixedSidebar';
import { QUIZ_TAKER_SIDEBAR_ITEMS } from '../../constants';

export const QuizTaker = () => {
  return (
    <EachPageWrapper title='Quiz Taker'>
      <FixedSidebar items={QUIZ_TAKER_SIDEBAR_ITEMS} />
    </EachPageWrapper>
  );
};
