import { MODES } from '../modes';

const MAIN_COLORS = {
  blue: {
    // for the quiz creators
    b1: 'rgb(168,221,242)',
    b2: 'rgb(0, 126, 198)',
    b3: 'rgb(1, 76, 161)',
    b3h: 'rgb(0, 53, 114)',
    b4: 'rgb(0, 38, 76)',
    b5: 'rgb(29, 66, 138)',
  },
  green: {
    // for the quiz takers
    g1: 'rgb(208, 255, 188)',
    g2: 'rgb(178, 210, 52)',
    g3: 'rgb(77, 184, 72)',
    g3h: 'rgb(63, 151, 59)',
    g4: 'rgb(0, 127, 71)',
    g5: 'rgb(0, 67, 29)',
  },
  default: {
    d2: 'rgb(166,166,166)',
    d2h: 'rgb(122,122,122)',
    d3: 'rgb(114, 114, 114)',
    d3h: 'rgb(72,72,72)',
  },
};

const getModeColor = (mode, color1, color2, color3) => {
  switch (mode) {
    case MODES.QUIZ_CREATOR:
      return color1;
    case MODES.QUIZ_TAKER:
      return color2;
    default:
      return color3;
  }
};

export const getTheme = (mode) => ({
  palette: {
    primary: {
      main: '#FCFCFC',
      secondary: '#EAEAEA',
    },
    info: {
      main: '#000000',
    },
    success: {
      main: 'rgb(25,135,84)',
    },
    error: {
      main: '#ff0033',
    },
    warning: {
      main: 'rgb(255, 153, 0)',
    },
    background: {
      main: '#fcfcfc',
      secondary: '#EAEAEA',
    },
    colors: {
      // For buttons and other colorful element BUT background of the very first page
      c1: getModeColor(mode, MAIN_COLORS.blue.b1, MAIN_COLORS.green.g1, MAIN_COLORS.default.d1),
      c2: getModeColor(mode, MAIN_COLORS.blue.b2, MAIN_COLORS.green.g2, MAIN_COLORS.default.d2),
      c3: getModeColor(mode, MAIN_COLORS.blue.b3, MAIN_COLORS.green.g3, MAIN_COLORS.default.d3),
      c3h: getModeColor(mode, MAIN_COLORS.blue.b3h, MAIN_COLORS.green.g3h, MAIN_COLORS.default.d3h),
      c4: getModeColor(mode, MAIN_COLORS.blue.b4, MAIN_COLORS.green.g4, MAIN_COLORS.default.d5),
      c5: getModeColor(mode, MAIN_COLORS.blue.b5, MAIN_COLORS.green.g5, MAIN_COLORS.default.d5),

      b1: MAIN_COLORS.blue.b1,
      b2: MAIN_COLORS.blue.b2,
      b3: MAIN_COLORS.blue.b3,
      b3h: MAIN_COLORS.blue.b3h,
      b4: MAIN_COLORS.blue.b4,
      b5: MAIN_COLORS.blue.b5,

      g1: MAIN_COLORS.green.g1,
      g2: MAIN_COLORS.green.g2,
      g3: MAIN_COLORS.green.g3,
      g3h: MAIN_COLORS.green.g3h,
      g4: MAIN_COLORS.green.g4,
      g5: MAIN_COLORS.green.g5,

      d2: MAIN_COLORS.default.d2,
      d2h: MAIN_COLORS.default.d2h,
      d3: MAIN_COLORS.default.d3,
      d3h: MAIN_COLORS.default.d3h,
    },
  },
  typography: {
    fontFamily: 'Museo Slap, sans-serif',
  },
});
