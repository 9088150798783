import { Button } from '@mui/material';
import styled from 'styled-components';

import { QUIZ_TAKE_PAGE_REDUCER_TYPES } from './reducer';

export const QuizTakeReview = ({ questions, answeredQuestions, dispatch }) => {
  const handleOpenQuestion = (index) => {
    dispatch({ type: QUIZ_TAKE_PAGE_REDUCER_TYPES.SET_OPENED_QUESTION, payload: index });
  };

  const handleKeyPress = (e, index) => {
    if (['Space', 'Enter', 'NumpadEnter'].includes(e.code)) {
      handleOpenQuestion(index);
    }
  };

  const handleSubmit = () => {
    // TODO add submit logic
  };

  return (
    <div>
      <h4>Review your answers</h4>

      {questions.map((question, index) => (
        <StyledQuestionContainer
          onClick={() => handleOpenQuestion(index)}
          tabIndex={0}
          onKeyPress={(e) => handleKeyPress(e, index)}>
          <h4>{question.questionText}</h4>
          <p>{question.options.find((option) => option.id === Number(answeredQuestions[question.id])).text}</p>
        </StyledQuestionContainer>
      ))}

      <StyledSubmitButton variant='contained' color='info' onClick={handleSubmit}>
        Submit
      </StyledSubmitButton>
    </div>
  );
};

const StyledQuestionContainer = styled.div`
  width: 600px;
  margin: 16px auto;
  border: 1px solid #92b1f1;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: 1px solid darkgreen;
  }
`;

const StyledSubmitButton = styled(Button)`
  margin-top: 24px !important;
`;
