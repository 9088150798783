import { useEffect, useReducer } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { MdAutoDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ActionTypes, initialState, quizzesPageReducer } from './quizzesPgaeReducer';
import { FixedSidebar } from '../fixedSidebar/FixedSidebar';
import { Waiting } from '../waiting';
import { ApiClient } from '../../api/apiClient';
import { handleApiError } from '../../api/axiosMethods';
import { StyledButton } from '../../buttons/styledButton/StyledButton';
import { QUIZ_CREATOR_SIDEBAR_ITEMS } from '../../constants';

export const QuizzesPage = () => {
  const auth = useAuthUser();
  const userId = auth.user.id;

  const [state, dispatch] = useReducer(quizzesPageReducer, initialState);
  const { waiting, quizzes } = state;
  const navigate = useNavigate();

  const createQuiz = () => {
    navigate('/quiz-creators/quizzes/create');
  };

  useEffect(() => {
    dispatch({ type: ActionTypes.START_LOAD_QUIZ });
    ApiClient.getQuizzes(userId)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: ActionTypes.LOAD_QUIZ_SUCCESS, payload: response.data.payload.quizzes });
        } else {
          dispatch({ type: ActionTypes.LOAD_QUIZ_FAILURE, payload: response.data.message });
        }
      })
      .catch((error) =>
        handleApiError(error, (msg) => dispatch({ type: ActionTypes.LOAD_QUIZ_FAILURE, payload: msg }))
      );
  }, [userId]);

  const clickOnQuiz = (quizId) => {
    navigate(`/quiz-creators/quizzes/${quizId}`);
  };

  const removeQuiz = (quizId) => {
    dispatch({ type: ActionTypes.START_DELETING_QUIZ });
    ApiClient.removeQuiz(userId, quizId)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: ActionTypes.DELETE_QUIZ_SUCCESS, payload: quizId });
        } else {
          dispatch({ type: ActionTypes.DELETE_QUIZ_FAILURE, payload: response.data.message });
        }
      })
      .catch((error) =>
        handleApiError(error, (msg) => dispatch({ type: ActionTypes.DELETE_QUIZ_FAILURE, payload: msg }))
      );
  };

  return (
    <>
      <FixedSidebar items={QUIZ_CREATOR_SIDEBAR_ITEMS} />

      {waiting ? (
        <Waiting />
      ) : (
        <Wrapper>
          <StyledButtonContainer>
            <StyledButton text='Create quiz' backgroundColor='#009020' hoverColor='#01681B' onClick={createQuiz} />
          </StyledButtonContainer>

          {quizzes &&
            quizzes.map((quiz) => (
              <QuizCardAndDeleteIconWrapper>
                <QuizCard key={quiz.id} onClick={() => clickOnQuiz(quiz.id)}>
                  <p>{quiz.title}</p>
                </QuizCard>
                <DeleteIconWrapper>
                  <MdAutoDelete color='red' onClick={() => removeQuiz(quiz.id)} />
                </DeleteIconWrapper>
              </QuizCardAndDeleteIconWrapper>
            ))}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 1200px;
  margin-left: 400px;
`;

const StyledButtonContainer = styled.div`
  float: right;

  position: relative;
  left: 24px;
`;

const QuizCardAndDeleteIconWrapper = styled.div`
  background: #ececec;
  width: 100%;

  padding-right: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  gap: 32px;

  &:nth-child(2) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &:nth-child(even) {
    background: #ddd;
  }

  &:nth-last-child(1) {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const QuizCard = styled.div`
  width: 250px;
  border-radius: 6px;
  padding: 16px;
  flex-grow: 1;
  
  &:hover {
    cursor: pointer;
  },
`;

const DeleteIconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  },
`;
