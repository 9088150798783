import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { useReducer } from 'react';
import styled from 'styled-components';

import { Options } from './components/Options';
import { CreateQuestionActionTypes, createQuestionModalReducer, initialCreateQuestionState } from './reducer';
import { validateQuestion } from './validateQuestion';
import { CloseButton } from '../components/CloseButton';
import { QUESTION_TYPES, questionTypes } from '../../../constants/questionTypes';
import { deepCopy } from '../../../utils/deepCopy';

export const CreateQuestionModal = ({
  isModalOpen,
  setIsModalOpen,
  selectedQuestion,
  setSelectedQuestion,
  saveQuestion,
}) => {
  const init = (initialState) => {
    if (selectedQuestion) return deepCopy(selectedQuestion);
    return deepCopy(initialState);
  };

  const [state, dispatch] = useReducer(createQuestionModalReducer, initialCreateQuestionState, init);

  const { questionText, questionType, options, answer, isFormSubmitted } = state;

  const closeModal = () => {
    setSelectedQuestion(null);
    setIsModalOpen(false);
  };

  const saveModal = async () => {
    dispatch({ type: CreateQuestionActionTypes.SUBMIT_FORM });

    if (!validateQuestion(questionText, questionType, options, answer)) {
      return;
    }

    // if (selectedQuestion) {
    //   await ApiClient.createQuestion(state);
    // } else {
    //   await ApiClient.editQuestion(state);
    // }

    saveQuestion(state, !selectedQuestion);
    setIsModalOpen(false);
  };

  const handleQuestionChange = (value) => {
    dispatch({ type: CreateQuestionActionTypes.SET_QUESTION_TEXT, payload: value });
  };

  const handleQuestionTypeChange = (value) => {
    dispatch({ type: CreateQuestionActionTypes.SET_QUESTION_TYPE, payload: value });
  };

  const handleAnswerChange = (value) => {
    dispatch({ type: CreateQuestionActionTypes.UPDATE_ANSWER, payload: value });
  };

  const addOption = () => {
    dispatch({ type: CreateQuestionActionTypes.ADD_OPTION });
  };

  return (
    <Modal open={isModalOpen}>
      <StyledModalBody component='form' onSubmit={(e) => e.preventDefault()}>
        <CloseButton closeModal={closeModal} />
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {selectedQuestion ? 'Edit question' : 'Create question'}
        </Typography>
        <TextField
          id='question-text'
          label='Question Text'
          multiline
          rows={6}
          fullWidth
          value={questionText}
          onChange={(e) => handleQuestionChange(e.target.value)}
          required
          error={isFormSubmitted && !questionText}
          sx={{ mt: 2 }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id='question-type-label'>Question type</InputLabel>
          <Select
            labelId='question-type-label'
            id='question-type'
            value={questionType}
            label='Question type'
            onChange={(e) => handleQuestionTypeChange(e.target.value)}>
            {Object.entries(questionTypes).map(([value, label]) => (
              <MenuItem value={value}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {(questionType === QUESTION_TYPES.MULTIPLE_CHOICE || questionType === QUESTION_TYPES.TRUE_OR_FALSE) && (
          <Options
            options={options}
            questionType={questionType}
            isFormSubmitted={isFormSubmitted}
            dispatch={dispatch}
          />
        )}

        {questionType === QUESTION_TYPES.SHORT_ANSWER && (
          <TextField
            id='question-answer'
            label='Correct answer'
            fullWidth
            value={answer}
            onChange={(e) => handleAnswerChange(e.target.value)}
            required
            error={isFormSubmitted && !answer}
            sx={{ mt: 2 }}
            multiline
            rows={3}
          />
        )}

        <Box display='flex' justifyContent='space-between' sx={{ mt: 2 }}>
          <Box>
            {questionType === QUESTION_TYPES.MULTIPLE_CHOICE && <Button onClick={addOption}>Add Option</Button>}
          </Box>
          <Box>
            <Button variant='outlined' color='error' onClick={closeModal} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant='contained' color='success' onClick={saveModal}>
              Save
            </Button>
          </Box>
        </Box>
      </StyledModalBody>
    </Modal>
  );
};

const StyledModalBody = styled(Box)`
  background: #eee;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  width: 100%;
  max-width: 500px;
  max-height: 80vh;
  overflow: auto;
`;
