import styled from 'styled-components';

export const AnswerOptions = ({ question, answer, setAnswer }) => {
  const handleOptionSelect = (id) => {
    setAnswer([id]);
  };

  const handleKeyPress = (e, id) => {
    if (['Space', 'Enter', 'NumpadEnter'].includes(e.code)) {
      handleOptionSelect(id);
    }
  };

  return question.options.map((option, index) => (
    <StyledOption
      tabIndex={0}
      isSelected={answer?.includes(option.id)}
      onClick={() => handleOptionSelect(option.id)}
      onKeyPress={(e) => handleKeyPress(e, option.id)}>
      <StyledOptionNumber isSelected={answer?.includes(option.id)}>
        {String.fromCharCode(65 + index)}
      </StyledOptionNumber>
      <StyledOptionText>{option.text}</StyledOptionText>
    </StyledOption>
  ));
};

const StyledOption = styled.div`
  padding: 16px;
  border: 1px solid ${(props) => (props.isSelected ? 'green' : '#92b1f1')};
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: 1px solid darkgreen;
  }
`;

const StyledOptionNumber = styled.h5`
  border: 1px solid #92b1f1;
  border-radius: 8px;
  padding: 4px 6px;
  margin: 0;
  ${(props) => props.isSelected && 'background: green; color: white'}
`;

const StyledOptionText = styled.p`
  margin: 0;
`;
