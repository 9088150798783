import { styled } from '@mui/material';
import { useRef, useState } from 'react';

import { useOnHoverOutside } from '../../customHooks/useOnHoverOutside';

export const NavbarItem = ({ title, content, isLink }) => {
  const [isDropdownMenuOpen, setIsDropDownMenuOpen] = useState(false);
  //TODO: Leave a check here proving that isLink and content cannot be fed at the same time

  const dropdownRef = useRef(null);

  const closeHoverMenu = () => {
    setIsDropDownMenuOpen(false);
  };

  useOnHoverOutside(dropdownRef, closeHoverMenu);

  return (
    <Wrapper ref={dropdownRef} onMouseOver={() => setIsDropDownMenuOpen(true)}>
      <NavbarItemsText isLink={isLink}>{title}</NavbarItemsText>
      {!isLink && isDropdownMenuOpen && (
        <DropdownMenu className='dropdown-menu'>
          {content.map((item) => (
            <DropdownItem href={item.link}>{item.text}</DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Wrapper>
  );
};

const NavbarItemsText = styled('div')(({ isLink }) => ({
  '&:hover': {
    cursor: 'pointer',
    textDecoration: isLink ? 'underline' : 'none',
  },
}));

const Wrapper = styled('div')({});

const DropdownMenu = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  background: '#fff',
  borderRadius: 5,
  width: 200,
  cursor: 'pointer',
});

const DropdownItem = styled('a')({
  padding: 10,
  color: '#000000',
  textDecoration: 'none',
  '&:hover': {
    background: '#ccc',
    '&:first-child': {
      borderRadius: '5px 5px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 5px 5px',
    },
  },
});
