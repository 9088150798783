import { TextField } from '@mui/material';

export const AnswerField = ({ answer, setAnswer }) => {
  return (
    <TextField
      id='question-answer'
      label='Answer'
      fullWidth
      multiline
      value={answer}
      onChange={(e) => setAnswer(e.target.value)}
      required
      sx={{ mt: 2 }}
    />
  );
};
